import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Paper
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const QuestionnaireBuilder = ({ questions, onQuestionsUpdate, selectedPatientId, isIntake }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [localQuestions, setLocalQuestions] = useState(questions);

  useEffect(() => {
    setLocalQuestions(questions);
  }, [questions]);

  const addQuestion = () => {
    if (localQuestions.length < 10) {
      const updatedQuestions = [...localQuestions, { text: '', editable: true }];
      setLocalQuestions(updatedQuestions);
      onQuestionsUpdate(updatedQuestions);
      setEditingIndex(localQuestions.length);
      setEditingText('');
    }
  };

  const removeQuestion = (index) => {
    const updatedQuestions = localQuestions.filter((_, i) => i !== index);
    setLocalQuestions(updatedQuestions);
    onQuestionsUpdate(updatedQuestions);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditingText(localQuestions[index].text);
  };

 const handleEditSubmit = (index) => {
    const updatedQuestions = [...localQuestions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      text: editingText,
    };
    setLocalQuestions(updatedQuestions);
    onQuestionsUpdate(updatedQuestions);
    setEditingIndex(null);
    setEditingText('');
  };

  const handleEditCancel = () => {
    setEditingIndex(null);
    setEditingText('');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addQuestion}
          disabled={localQuestions.length >= 10}
          sx={{
            color: 'primary.main',
            borderColor: 'primary.main',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'primary.light',
            },
            mr: 2
          }}
        >
          Add Question
        </Button>
        <Typography variant="body2" color="textSecondary">
          {localQuestions.length}/10 Questions
        </Typography>
      </Box>
      <List>
        {localQuestions.map((question, index) => (
          <ListItem
            key={index}
            component={Paper}
            elevation={2}
            sx={{
              mb: 2,
              p: 2,
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
            }}
          >
            {editingIndex === index ? (
              <Box display="flex" alignItems="center" width="100%">
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={editingText}
                  onChange={(e) => setEditingText(e.target.value)}
                  variant="outlined"
                />
                <IconButton onClick={() => handleEditSubmit(index)} color="primary">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleEditCancel} color="secondary">
                  <CancelIcon />
                </IconButton>
              </Box>
            ) : (
              <>
                <ListItemText
                  primary={question.text}
                  sx={{ pr: 2 }}
                />
                <ListItemSecondaryAction>
                  {question.editable && (
                    <>
                      <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(index)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeQuestion(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default QuestionnaireBuilder;
import React, {useState, useEffect, useRef} from 'react';
import {useAuth} from '../context/AuthContext';
import {usePatient} from '../context/PatientContext';
import {
    Box,
    Paper,
    Typography,
    TextField,
    IconButton,
    Card,
    CardContent,
    CardActionArea,
    Alert
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {Resizable} from 're-resizable';
import ReactMarkdown from 'react-markdown';
import TypewriterEffect from './TypewriterEffect';
import styles from './ChatSidebar.module.css';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const ChatSidebar = ({onResize, initialWidth}) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sidebarWidth, setSidebarWidth] = useState(initialWidth);
    const [newMessageId, setNewMessageId] = useState(null);
    const {user} = useAuth();
    const {selectedPatient} = usePatient();
    const messagesEndRef = useRef(null);
    const chatContentRef = useRef(null);
    const isTyping = useRef(false);
    const shouldScrollRef = useRef(true);
    const lastMessageRef = useRef(null);
    const scrollIntervalRef = useRef(null);


    const samplePrompts = [
        "Which of Erikson's development stages might this client have struggled with?",
        "Suggest role-play scenarios to practice boundary-setting with this client.",
        "How can CBT help address this client's attachment issues?",
        "Recommend reframing techniques to boost the client's self-esteem.",
        "Propose strategies for improving this client's relationship conflict management.",
        "Outline a mindfulness exercise tailored to this client's specific needs."
    ];

    const scrollToBottom = (behavior = 'smooth') => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior});
        }
    };

    useEffect(() => {
        if (selectedPatient) {
            fetchConversationHistory();
            setError(null);
        } else {
            setMessages([]);
            setError(null);
        }
    }, [selectedPatient]);

    // Scroll handling for new messages and typing
    useEffect(() => {
        let scrollInterval;

        if (isTyping.current) {
            scrollInterval = setInterval(() => {
                if (shouldScrollRef.current) {
                    scrollToBottom('auto');
                }
            }, 50);
        }

        return () => clearInterval(scrollInterval);
    }, [isTyping.current]);


    // Handle scroll position tracking
    useEffect(() => {
        const handleScroll = () => {
            if (chatContentRef.current) {
                const {scrollTop, scrollHeight, clientHeight} = chatContentRef.current;
                const isNearBottom = scrollTop + clientHeight >= scrollHeight - 100;
                shouldScrollRef.current = isNearBottom;
            }
        };

        const contentRef = chatContentRef.current;
        if (contentRef) {
            contentRef.addEventListener('scroll', handleScroll);
            return () => contentRef.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const fetchConversationHistory = async () => {
        try {
            const response = await fetch(`/api/get_conversation_history/${user.uid}/${selectedPatient.id}`);
            const data = await response.json();
            setMessages(data);
            // Set scroll flag to true after loading history
            setTimeout(() => {
                shouldScrollRef.current = true;
                messagesEndRef.current?.scrollIntoView({behavior: 'auto'});
            }, 100);
        } catch (error) {
            console.error('Error fetching conversation history:', error);
        }
    };

    const handleSendMessage = async (messageToSend = inputMessage) => {
        if (messageToSend.trim() === '') return;
        if (!selectedPatient) {
            setError("Please select a client before sending a message.");
            return;
        }

        setError(null);
        const newMessage = {role: 'user', text: messageToSend};

        // Update messages and immediately scroll
        setMessages(prev => {
            const updatedMessages = [...prev, newMessage];
            // Use setTimeout to ensure the DOM has updated
            setTimeout(() => {
                shouldScrollRef.current = true;
                scrollToBottom('auto');
            }, 0);
            return updatedMessages;
        });

        setInputMessage('');
        setIsLoading(true);

        try {
            const response = await fetch(`/api/send_message/${user.uid}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    message: messageToSend,
                    patient_id: selectedPatient.id,
                }),
            });

            const data = await response.json();
            const newBotMessageId = Date.now();
            isTyping.current = true;
            setNewMessageId(newBotMessageId);
            shouldScrollRef.current = true;

            setMessages(prev => {
                const updatedMessages = [
                    ...prev,
                    {id: newBotMessageId, role: 'assistant', text: data.message}
                ];
                // Ensure scroll after bot message is added
                setTimeout(() => scrollToBottom('auto'), 0);
                return updatedMessages;
            });
        } catch (error) {
            console.error('Error sending message:', error);
            setError("An error occurred while sending the message. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleTypewriterComplete = () => {
        isTyping.current = false;
        if (shouldScrollRef.current) {
            scrollToBottom('smooth');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const ChatLogoWrapper = () => (
        <Box
            sx={{
                width: '48px',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                marginRight: '8px',
            }}
        >
            <img
                src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_logo-removebg-preview.png?alt=media&token=c371a13e-f2b7-4887-b168-4470dcb7ef8a"
                alt="Psyntel Logo"
                style={{
                    width: '36px',
                    height: '36px',
                    objectFit: 'contain',
                }}
            />
        </Box>
    );

    return (
        <Resizable
            size={{width: sidebarWidth, height: '100%'}}
            minWidth={300}
            maxWidth="50vw"
            enable={{left: true}}
            onResizeStop={(e, direction, ref, d) => {
                const newWidth = sidebarWidth + d.width;
                setSidebarWidth(newWidth);
                onResize(newWidth);
            }}
            handleComponent={{
                left: <ResizeHandle/>,
            }}
            className={styles.chatSidebarContainer}
        >
            <Paper elevation={3} className={styles.chatSidebarPaper}>
                <Box
                    className={styles.chatSidebarContent}
                    ref={chatContentRef}
                    style={{height: 'calc(100% - 60px)'}}
                >
                    {messages.length === 0 ? (
                        <Box
                            className={styles.chatSidebarSamplePrompts}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                padding: '20px',
                            }}
                        >
                            <Typography variant="body1"
                                        sx={{mb: 3, textAlign: 'center', px: 2, fontWeight: 'bold'}}>
                                Chat with Psyntel about your client by sending a message or selecting a starter
                                prompt
                                below.
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2
                            }}>
                                {samplePrompts.map((prompt, index) => (
                                    <Card
                                        key={index}
                                        className={styles.chatSidebarPromptCard}
                                        sx={{
                                            width: '90%',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'translateY(-5px)',
                                                boxShadow: 3,
                                            },
                                        }}
                                    >
                                        <CardActionArea onClick={() => handleSendMessage(prompt)}>
                                            <CardContent>
                                                <Typography variant="body2">
                                                    {prompt}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </Box>
                        </Box>
                    ) : (
                        messages.map((message, index) => (
                            <Box
                                key={message.id || index}
                                ref={index === messages.length - 1 ? lastMessageRef : null}
                                className={`${styles.chatSidebarMessageContainer} ${
                                    message.role === 'user' ? styles.chatSidebarMessageContainerUser : ''
                                }`}
                            >
                                {message.role === 'assistant' && <ChatLogoWrapper/>}
                                <Box
                                    className={`${styles.chatSidebarMessage} ${
                                        message.role === 'user'
                                            ? styles.chatSidebarMessageUser
                                            : styles.chatSidebarMessageAssistant
                                    }`}
                                >
                                    {message.role === 'assistant' && message.id === newMessageId ? (
                                        <TypewriterEffect
                                            text={message.text}
                                            speed={10}
                                            onComplete={() => {
                                                isTyping.current = false;
                                                // Final scroll after typing completes
                                                if (shouldScrollRef.current) {
                                                    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
                                                }
                                            }}
                                        />
                                    ) : (
                                        <ReactMarkdown>{message.text}</ReactMarkdown>
                                    )}
                                </Box>
                            </Box>
                        ))
                    )}
                    {isLoading && (
                        <Box
                            className={`${styles.chatSidebarMessageContainer} ${styles.chatSidebarMessageContainerAssistant}`}
                        >
                            <ChatLogoWrapper/>
                            <Box
                                className={`${styles.chatSidebarMessage} ${styles.chatSidebarMessageAssistant}`}
                            >
                                <div className={styles.chatSidebarTypingIndicator}></div>
                            </Box>
                        </Box>
                    )}
                    <div ref={messagesEndRef}/>
                </Box>
                <Box className={styles.chatSidebarInputContainer}>
                    {error && (
                        <Alert severity="error" sx={{mb: 2}}>{error}</Alert>
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Type your message here..."
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    color="primary"
                                    onClick={() => handleSendMessage()}
                                    disabled={isLoading || !inputMessage.trim()}
                                >
                                    <SendIcon/>
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
            </Paper>
        </Resizable>
    );
};

const ResizeHandle = () => (
    <div
        sx={{
            position: 'absolute',
            left: 3,
            top: 0,
            bottom: 0,
            width: 2,
            cursor: 'col-resize',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
        }}
    >
        <DragHandleIcon sx={{transform: 'rotate(90deg)', color: 'action.active'}}/>
    </div>
);

export default ChatSidebar;
import React, { useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Typography, Button, Menu, MenuItem } from '@mui/material';
import styles from './Sidebar.module.css';

const Sidebar = ({ patients, onSelectPatient, onAddPatient, setPatients }) => {
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handlePatientClick = (patient) => {
    setSelectedPatientId(patient.id);
    onSelectPatient(patient);
  };

  const handleContextMenu = (event, patient) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            patient: patient
          }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleDelete = useCallback(async () => {
    if (contextMenu) {
      try {
        const response = await fetch(`/api/delete_patient/${user.uid}/${contextMenu.patient.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setPatients(prevPatients => prevPatients.filter(p => p.id !== contextMenu.patient.id));
          if (selectedPatientId === contextMenu.patient.id) {
            setSelectedPatientId(null);
            onSelectPatient(null);
          }
        } else {
          console.error('Failed to delete patient');
          // You might want to show an error message to the user here
        }
      } catch (error) {
        console.error('Error deleting patient:', error);
        // You might want to show an error message to the user here
      }
      handleClose();
    }
  }, [contextMenu, user.uid, setPatients, selectedPatientId, onSelectPatient]);

   const AddClientButton = () => (
    <Button
      variant="contained"
      startIcon={<AddOutlinedIcon />}
      onClick={onAddPatient}
      fullWidth
      sx={{
        py: 1.5,
        mb: 3,
        backgroundColor: '#3a7ff2',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#1e5fcf',
        },
      }}
    >
      Add Client
    </Button>
  );

  return (
    <div className={styles.sidebarContent}>
      {patients.length === 0 ? (
        <Box className={styles.emptyStateContainer}>
          <Box>
            <PersonAddIcon className={styles.emptyStateIcon} />
            <Typography variant="h6" className={styles.emptyStateTitle}>
              No Clients Yet
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <AddClientButton />
          <ul className={styles.patientList}>
            {patients.map(patient => (
              <li
                key={patient.id}
                onClick={() => handlePatientClick(patient)}
                onContextMenu={(event) => handleContextMenu(event, patient)}
                className={selectedPatientId === patient.id ? styles.selected : ''}
              >
                {patient.firstName} {patient.lastName}
                <span className={styles.patientId}>(ID: {patient.id})</span>
              </li>
            ))}
          </ul>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleDelete}>Delete Client</MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default Sidebar;
import React, {useState, useEffect, useCallback} from 'react';
import {useAuth} from '../context/AuthContext';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Chip,
    useTheme,
    Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ViewResponses = ({selectedPatientId}) => {
    const {user} = useAuth();
    const [responses, setResponses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expandedSession, setExpandedSession] = useState(null);
    const theme = useTheme();

    const fetchResponses = useCallback(async (patientId) => {
        setLoading(true);
        try {
            const response = await fetch(`/api/view_responses/${user.uid}/${patientId}`);
            const data = await response.json();
            if (response.ok) {
                const submittedResponses = data.responses.filter(questionnaire =>
                    questionnaire.responses && questionnaire.responses.length > 0
                );
                setResponses(submittedResponses || []);
            } else {
                throw new Error(data.error || 'Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [user.uid]);

    useEffect(() => {
        if (selectedPatientId) {
            fetchResponses(selectedPatientId);
        }
    }, [selectedPatientId, fetchResponses]);

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'No timestamp';

        let date;
        if (timestamp.seconds) {
            // Handle Firestore Timestamp
            date = new Date(timestamp.seconds * 1000);
        } else if (timestamp.toDate) {
            // Handle Firestore Timestamp (alternative format)
            date = timestamp.toDate();
        } else {
            // Handle string timestamp
            date = new Date(timestamp);
        }

        if (isNaN(date.getTime())) {
            console.error('Invalid timestamp:', timestamp);
            return 'Invalid Date';
        }

        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    if (loading) {
        return <Typography>Loading responses...</Typography>;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    if (responses.length === 0) {
        return <Typography>No client responses have been received yet.</Typography>;
    }

    return (
        <Box>
            {responses.map((questionnaire) => (
                <Accordion
                    key={questionnaire.session_id}
                    expanded={expandedSession === questionnaire.session_id}
                    onChange={() => setExpandedSession(expandedSession === questionnaire.session_id ? null : questionnaire.session_id)}
                    sx={{
                        mb: 2,
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: '8px',
                        '&:before': {
                            display: 'none',
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        sx={{
                            backgroundColor: 'background.paper',
                            borderBottom: expandedSession === questionnaire.session_id ? '1px solid black' : 'none',
                        }}
                    >
                        <Chip
                            label={formatTimestamp(questionnaire.timestamp)}
                            size="small"
                            color="primary"
                            variant="outlined"
                            sx={{mr: 2}}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {questionnaire.questions.map((question, index) => (
                                <ListItem key={index} sx={{flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle1" color="text.primary" fontWeight="bold">
                                                {`${index + 1}. ${question.text}`}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant="body2" sx={{mt: 1}}>
                                                {questionnaire.responses[index]?.answer || 'No answer provided'}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default ViewResponses;
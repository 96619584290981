import React, {useState} from 'react';
import {Box, Paper, Typography, TextField, Button, InputAdornment, Snackbar, Alert} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {useAuth} from '../context/AuthContext';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

const AppFooter = () => {
    const [feedback, setFeedback] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const {user} = useAuth();

    const handleSubmitFeedback = async () => {
        if (!user) {
            setSnackbarMessage('User not authenticated. Please log in to submit feedback.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        try {
            const response = await fetch('/api/submit-feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await user.getIdToken()}`
                },
                body: JSON.stringify({
                    feedback,
                    userId: user.uid,
                    userEmail: user.email
                })
            });

            const data = await response.json();

            if (response.ok) {
                setFeedback('');
                setSnackbarMessage(data.message || 'Feedback submitted successfully!');
                setSnackbarSeverity('success');
            } else {
                setSnackbarMessage(data.error || 'Failed to submit feedback. Please try again.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setSnackbarMessage('An error occurred while submitting feedback.');
            setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);
    };

    return (
        <Paper sx={{
            backgroundColor: '#f5f8ff',
            padding: '20px',
            marginTop: '40px',
            marginLeft: '-12px',
            marginRight: '-22px',
            marginBottom: '-20px'

        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
            }}>
                <Box sx={{flex: '1 1 300px', marginRight: '20px', marginBottom: '20px'}}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_concept-removebg-preview-2.png?alt=media&token=e9b4c40a-f63e-40a2-a0e4-9a3f299e9c78"
                        alt="Psyntel Logo"
                        style={{height: '100px'}}
                    />
                    <Box sx={{display: 'flex', gap: '20px', paddingLeft: '15px'}}>
                        <a href="https://psyntel.com/#termsofservice" style={{color: '#999', textDecoration: 'none'}}>Terms
                            of Service</a>
                        <a href="https://psyntel.com/#privacypolicy" style={{color: '#999', textDecoration: 'none'}}>Privacy
                            Policy</a>
                    </Box>
                </Box>

                <Box sx={{flex: '0.85 0 20px'}}>
                    <TextField
                        multiline
                        rows={1}
                        variant="outlined"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        fullWidth
                        sx={{marginBottom: '10px', backgroundColor: '#fff'}}
                        placeholder="How can we make Psyntel better for you?"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CampaignOutlinedIcon/>
                                </InputAdornment>
                            ),
                              sx: {
                height: '100px', // Set the input area height
                alignItems: 'center', // Aligns the input area at the top
                                  paddingBottom: '45px'
            },
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={handleSubmitFeedback}
                        disabled={!feedback.trim() || !user}
                        sx={{
                            backgroundColor: '#4a90e2',
                            '&:hover': {backgroundColor: '#3a78c2'},
                            '&:disabled': {backgroundColor: '#cccccc'},
                        }}
                    >
                        Submit Feedback
                    </Button>
                </Box>
            </Box>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default AppFooter;
import React, { useState } from 'react';
import './AddPatientModal.css';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const AddPatientModal = ({ show, handleClose, onPatientAdded }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('User not authenticated. Please log in again.');
      return;
    }
    setIsLoading(true);
    setError('');

    try {
      const token = await user.getIdToken();
      const response = await axios.post(`/api/add_patient/${user.uid}`,
        {
          firstName,
          lastName,
          email
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 201) {
        onPatientAdded(response.data.patient);
        handleClose();
      } else {
        setError('Failed to add client. Please try again.');
      }
    } catch (error) {
      console.error('Error adding patient:', error);
      setError(error.response?.data?.error || 'An error occurred while adding the client.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add New Client</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {error && <p className="error-message">{error}</p>}
          <div className="modal-actions">
            <button type="submit" disabled={isLoading || !user}>
              {isLoading ? 'Adding...' : 'Add Client'}
            </button>
            <button type="button" onClick={handleClose} disabled={isLoading}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPatientModal;
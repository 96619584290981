import * as XLSX from 'xlsx';

export const readICDCodesFromLocalFile = async () => {
  const url = `${process.env.PUBLIC_URL}/ICD10-Jan2024.xlsx`;

  const response = await fetch(url);
  const data = await response.arrayBuffer();
  const workbook = XLSX.read(data, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

  // Combine code and description
  const icdCodes = json.slice(1).map(([code, description]) => ({
    combined: `${code}: ${description}`
  }));

  return icdCodes;
};

import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import './FillQuestionnaire.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Box, Typography, Button, CircularProgress, Checkbox, FormControlLabel} from '@mui/material';

const FillQuestionnaire = () => {
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState({});
    const [message, setMessage] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionId, setSessionId] = useState(null);
    const [autosaveIndicator, setAutosaveIndicator] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const {token, patient_id} = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`/api/fill_questionnaire/${token}/${patient_id}`);
                const data = await response.json();
                if (response.ok) {
                    setQuestions(data.questions);
                    setSessionId(data.session_id);

                    // Load saved responses from localStorage
                    const savedResponses = localStorage.getItem(`questionnaire_${patient_id}_${token}`);
                    if (savedResponses) {
                        setResponses(JSON.parse(savedResponses));
                    }
                } else {
                    setMessage(data.error || 'Failed to fetch questionnaire.');
                }
            } catch (error) {
                console.error('Error:', error);
                setMessage('An error occurred while fetching the questionnaire.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchQuestions();
    }, [token, patient_id]);

    const handleInputChange = (questionIndex, value) => {
        const newResponses = {
            ...responses,
            [`response_${questionIndex + 1}`]: value.slice(0, 1500)
        };
        setResponses(newResponses);
        // Autosave to localStorage
        localStorage.setItem(`questionnaire_${patient_id}_${token}`, JSON.stringify(newResponses));
        // Show autosave indicator
        setAutosaveIndicator('Saving...');
        setTimeout(() => setAutosaveIndicator('Saved'), 500);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!agreedToTerms) {
            setMessage('Please agree to the Terms of Service and Privacy Policy.');
            return;
        }
        if (Object.values(responses).some(response => response.length > 1500)) {
            setMessage('One or more responses exceed the 1500 character limit.');
            return;
        }
        setIsSubmitting(true);
        setMessage('');

        const formData = new FormData();
        formData.append('token', token);
        formData.append('session_id', sessionId);
        Object.entries(responses).forEach(([key, value]) => {
            formData.append(key, value);
        });

        try {
            const response = await fetch('/api/submit_questionnaire', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                // The response is HTML, so we'll let the browser handle it
                document.open();
                document.write(await response.text());
                document.close();
            } else {
                const errorData = await response.json();
                setMessage(errorData.error || 'Failed to submit questionnaire. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred while submitting the questionnaire. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    console.log('Rendering. showSuccessMessage:', showSuccessMessage);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (showSuccessMessage) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    backgroundColor: '#f0f8ff',
                    borderRadius: 2,
                    boxShadow: 3,
                    maxWidth: 400,
                    margin: 'auto',
                }}
            >
                <CheckCircleOutlineIcon sx={{fontSize: 60, color: 'success.main', mb: 2}}/>
                <Typography variant="h5" gutterBottom>
                    Questionnaire Submitted Successfully!
                </Typography>
                <Typography variant="body1" align="center" sx={{mb: 2}}>
                    Thank you for completing the questionnaire. Your responses have been recorded and will be reviewed
                    by your practitioner.
                </Typography>
                <Typography variant="body2" align="center" sx={{fontStyle: 'italic'}}>
                    You may now close this window.
                </Typography>
            </Box>
        );
    }

    return (
        <div className="fill-questionnaire-page">
            {isLoading ? (
                <div>Loading...</div>
            ) : showSuccessMessage ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 4,
                        backgroundColor: '#f0f8ff',
                        borderRadius: 2,
                        boxShadow: 3,
                        maxWidth: 400,
                        margin: 'auto',
                    }}
                >
                    <CheckCircleOutlineIcon sx={{fontSize: 60, color: 'success.main', mb: 2}}/>
                    <Typography variant="h5" gutterBottom>
                        Questionnaire Submitted Successfully!
                    </Typography>
                    <Typography variant="body1" align="center" sx={{mb: 2}}>
                        Thank you for completing the questionnaire. Your responses have been recorded and will be
                        reviewed
                        by your practitioner.
                    </Typography>
                    <Typography variant="body2" align="center" sx={{fontStyle: 'italic'}}>
                        You may now close this window.
                    </Typography>
                </Box>
            ) : (
                <div className="fill-questionnaire">
                    <h1>Questionnaire</h1>
                    <p className="instructions">
                        Instructions: Below are {questions.length} questions designed to gain a better understanding of
                        you.
                        Please provide detailed responses, as this will help tailor the support to your needs.
                        Your responses are automatically saved as you type, so you can return to this page later if
                        needed.
                    </p>
                    {message && <Typography color="error" sx={{mt: 2, mb: 2}}>{message}</Typography>}
                    <div className="autosave-indicator">{autosaveIndicator}</div>
                    <form onSubmit={handleSubmit}>
                        {questions.map((question, index) => (
                            <div key={index} className="question-item">
                                <p><strong>{question.number}.</strong> {question.text}</p>
                                <textarea
                                    value={responses[`response_${index + 1}`] || ''}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    required
                                    maxLength={1500}
                                />
                                <p className="character-count">
                                    {(responses[`response_${index + 1}`] || '').length}/1500 characters
                                </p>
                            </div>
                        ))}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreedToTerms}
                                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                                    name="agreeTerms"
                                />
                            }
                            label={
                                <span>
                                    I agree to the Psyntel Inc. <a href="https://psyntel.com/#termsofservice"
                                                                   className="hyperlink-blue">Terms of Service</a> and <a
                                    href="https://psyntel.com/#privacypolicy"
                                    className="hyperlink-blue">Privacy Policy</a>
                                </span>
                            }
                        />
                        <Button
                            type="submit"
                            disabled={!agreedToTerms || isSubmitting}
                            variant="contained"
                            color="primary"
                            sx={{mt: 2, display: 'block', width: '100%'}}
                        >
                            {isSubmitting ? <CircularProgress size={24}/> : 'Submit Questionnaire'}
                        </Button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default FillQuestionnaire;
// src/index.js
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {AuthProvider} from './context/AuthContext';
import {PatientProvider} from './context/PatientContext';

const Root = () => {
    return (
        <AuthProvider>
            <PatientProvider>
                <App/>
            </PatientProvider>
        </AuthProvider>
    );
};

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
